/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  background-color: #0e1951;
}

* {
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins";
}

html, body {
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}
